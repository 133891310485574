import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

import SoundCloudSVG from "../svgs/soundcloudSVG"
import BandCampSVG from "../svgs/bandcampSVG"
import SpotifySVG from "../svgs/spotifySVG"

const LinksPage = () => (

 <Layout>
   	<SEO title="LINKS" />

   	<h1 style={{color:"green", textAlign:'center'}}>LINKS</h1>

      <div>
         <img src={`/../gifs/ufo.gif`} style={{marginBottom: '0'}}/>         
      </div>
   	
      <div 
   		style={{
   			height: '20vh',
   			margin: 'auto',
   			display: 'flex',
   			flexDirection: 'column',
   			justifyContent: 'space-around',
            fontSize: '20px',
            textAlign: 'center',
            marginBottom: '30px'
   		}}
   	>
         <a href="https://soundcloud.com/the-steech" target="_blank" rel="noopener noreferrer" style={{margin: '0 auto'}}>
            <div style={{display: 'flex', flexDirection:'row', justifyContent: 'flex-start', color: 'white'}}>
   		      <SoundCloudSVG/>
               <div style={{marginLeft: '12px'}}>SOUNDCLOUD</div>
            </div>
         </a>


         {/*<a href="https://thesteech.bandcamp.com/" target="_blank" rel="noopener noreferrer" style={{margin: '0 auto'}}>
                     <div style={{display: 'flex', flexDirection:'row', justifyContent: 'flex-start', color: 'white'}}>
                        <BandCampSVG/>
                        <div style={{marginLeft: '12px'}}>BANDCAMP</div>
                     </div>
                  </a>
         
                  <a href="" target="_blank" rel="noopener noreferrer" style={{margin: '0 auto'}}>
                     <div style={{display: 'flex', flexDirection:'row', justifyContent: 'flex-start', color: 'white'}}>
                        <SpotifySVG/>
                        <div style={{marginLeft: '12px'}}>SPOTIFY</div>
                     </div>
                  </a>
         
                  <a>APPLE MUSIC</a>*/}

   	</div>
      <div>
         <img src={`/../gifs/train.gif`}/>         
      </div>

</Layout>
)

export default LinksPage
